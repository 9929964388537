<template>
  <tr
    @click="detail(item.id)"
  >
    <td>{{item.id}}</td>
    <td>{{item.username}}</td>
    <td>{{item.phone}}</td>
    <td>{{item.email}}</td>
    <td>{{item.organization.name}}</td>
    <td>{{isOnlineItem}}</td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import listItem from '@/components/mixins/listItem'

export default {
  mixins: [formatters, listItem],
  name: 'ListItem',
  computed: {
    isOnlineItem () {
      return this.item.is_online ? 'Да' :  this.item.last_online ? this.timestampToDate(this.item.last_online) : 'Нет'
    }
  }
}
</script>

<style scoped>

</style>
