import { translate } from '@/messages'
export default [
  {
    text: translate('base.id'),
    align: 'start',
    value: 'id'
  },
  {
    text: translate('user.username'),
    align: 'start',
    value: 'username'
  },
  {
    text: translate('user.phone'),
    align: 'start',
    value: 'phone'
  },
  {
    text: translate('user.email'),
    align: 'start',
    value: 'email'
  },
  {
    text: translate('organization.name'),
    align: 'start',
    value: 'organization',
    sortable: false
  },
  {
    text: translate('user.online'),
    align: 'start',
    value: 'is_online'
  }
]
